<template>
  <div>
    <v-container id="google-maps" fluid tag="section" v-if="usertype == 'admin'">
      <v-row>
        <v-col cols="12">

          <v-row>
            <v-col cols="12" sm="12">
              <v-card class="mx-auto">
                <v-sheet class="pa-3 primary lighten-2">
                  <v-text-field v-model="search" label="Search Company Directory" dark flat solo-inverted hide-details
                    clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                  <v-checkbox v-model="caseSensitive" dark hide-details label="Case sensitive search"></v-checkbox>
                </v-sheet>
                <v-card-text>
                  <v-treeview :items="PortalFiles.items" :search="search" :filter="filter" :open.sync="open">
                    <template v-slot:prepend="{ item }">
                      <v-icon @click="openAddDialog(item)" v-if="item.children" v-text="`mdi-plus`"></v-icon>
                      <v-icon v-if="!item.children || item.children.length == 0" @click="DeleteItemPortal(item)"
                        v-text="`mdi-close`"></v-icon>
                      <v-icon v-if="item.children"
                        v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"></v-icon>
                      <v-icon v-if="!item.children"
                        v-text="`mdi-${item.id === 1 ? 'home-variant' : 'open-in-new'}`"></v-icon>
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-col>

      </v-row>

    </v-container>
    <v-dialog v-model="dialog" activator="parent" width="auto" style="margin: 5%;">
      <v-card style="padding: 10px;">
        <v-radio-group v-model="radios">
          <v-radio label="File" :value="1"></v-radio>
          <v-radio label="Folder" :value="2"></v-radio>
        </v-radio-group>
        <div v-if="radios == 2">
          <v-text-field label="Folder Name" v-model="foldername"></v-text-field>
        </div>
        <div v-if="radios == 1">
          <v-text-field label="file Name" v-model="filename"></v-text-field>
          <v-text-field label="file url" v-model="fileUrl"></v-text-field>
        </div>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn @click="SavePortalItem" color="primary" block>Add</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="primary" block @click="dialog = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { db, fb } from '@/db'





export default {
  name: 'DashboardIcons',
  data: () => ({
    dialog: false,
    SelectedItem: null,
    foldername: '',
    filename: '',
    fileUrl: '',
    radios: 1,
    open: [1, 2],
    search: null,
    caseSensitive: false
  }),
  computed: {
    usertype() {
      return this.$store.state.userType
    },
    PortalFiles() {
      return this.$store.state.PortalFiles[0]
    },

  },
  created() {
    this.$store.dispatch('bindportal');
  },
  methods: {
    openAddDialog(item) {
      this.dialog = true;
      this.SelectedItem = item;
    },
    
    DeleteItemPortal(item) {
      this.SelectedItem = item;
      if (confirm('Are you sure you want to delete file from the database?')) {
        this.removeElement(this.PortalFiles.items,item.id)
        debugger
      } 
    },
    removeElementFromArray(arr, id) {
      debugger
      arr.flatMap (
        o => o.id === id
          ? [... (o .children || []).map (o => this.removeElement (o, id))]
          : [this.removeElement (o, id)]
      );
    },
    removeElement(obj, id) {  
      debugger
      Array .isArray (obj)
        ? this.removeElementFromArray (obj, id)
        : {... obj, ... (obj .children ? {children: this.removeElementFromArray (obj .children, id)} : {}) }
    },
    SavePortalItem() {
      if (this.radios == 2) {
        debugger
        this.SelectedItem.children.push({
          id: this.SelectedItem.id * 100 + this.SelectedItem.children.length + 1,
          name: this.foldername,
          children: []
        })
        alert(this.SelectedItem.id)
      } else {
        this.SelectedItem.children.push({
          id: this.SelectedItem.id * 100 + this.SelectedItem.children.length + 1,
          name: this.filename,
          url: this.fileUrl
        })
      }
      var context = this;
      db.collection('Portal')
        .doc('PzerNhfbYjkrhrYdbs96')
        .update(this.PortalFiles)
        .then(_ => {
          context.foldername = '';
          context.filename = '';
          context.fileUrl = ''
          alert("Item has been added successfully");
        })
    }



  }
}


</script>